<template>
  <div class="loginTop">
                    <h2>Welcome to<br><strong>Remote Support!</strong></h2>
                    <p>To start working, please finish your registration.</p>
                </div>
                <h3>Registration</h3>
                <div class="loginContent desktop-content">
                   <form v-on:submit.prevent="onClientregister()">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>First Name</label>
                                    <input class="form-control" type="text" placeholder="Your first name" v-model="first_name">
                                 <p class="error" v-if="errors.first_name">{{ errors.first_name }}</p>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>Last Name</label>
                                    <input class="form-control" type="text" placeholder="Your last name" v-model="last_name">
                                    <p class="error" v-if="errors.last_name">{{ errors.last_name }}</p>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>Phone number</label>
                                    <input class="form-control" type="text" placeholder="Your Phone Number" v-model="phone">
                                </div>
                            </div>
                             <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>Postcode</label>
                                    <input class="form-control" type="text" placeholder="Your postcode" v-model="postcode">
                                </div>
                            </div> 
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>Country</label>
                                    <select class="form-control" v-model="country">
                                        <option value="Portugal">Portugal</option>
                                        <option value="France">France</option>
                                        <option value="Germany">Germany</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label>Language</label>
                                    <select class="form-control" v-model="language">
                                        <option value="en">English</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3 password">
                                    <label>Password</label>
                                    <input class="form-control" :type="passwordType" minlength="6" pattern=".{6,}" title="6 characters minimum" placeholder="Your password" v-model.trim="password" autocomplete="off">
                                <span class="eye"><i v-on:click="switchVisibility()" :class="faClass" aria-hidden="true"></i></span>
                                <p class="error" v-if="errors.password">{{ errors.password }}</p>

                               </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3 password">
                                    <label>Confirm Password</label>
                                    <input class="form-control" :type="passwordType" minlength="6" pattern=".{6,}" title="6 characters minimum" placeholder="Your password" v-model.trim="conf_password" autocomplete="off">
                                 <span class="eye"><i v-on:click="switchVisibilConf()" :class="faClass" aria-hidden="true"></i></span>

                                </div>
                            </div>
                        </div>
                        <p class="text-center"><button class="btn" type="submit">Confirm Registration</button></p>
                    </form>
                </div>   
</template>

<script>
import {  mapMutations } from 'vuex'
import ClientValidation from '@/services/ClientValidation';
import axiosInstance from "@/services/AxiosTokenInstance";
import { LOADING_SPINNER_SHOW_MUTATION } from '@/store/storeconstants';
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },  
    data(){
        return{
            title: 'Login',
            passwordType: 'password',
            faClass: 'fa fa-eye-slash',
            first_name: '',
            last_name: '',
            password: '',
            conf_password:'',
            phone:'',
            postcode:'',
            country:'',
            language:'',
            errors: [],
            serverError: '',
        }
    },
    methods: {
        // ...mapActions('auth',{
        //     client_register: CLIENT_REGISTER,
        // }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        switchVisibility(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        switchVisibilConf(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        // async onClientregister(){
        //     this.userId = this.$route.params.userid;
        //     this.serverError = '';
        //     let validations = new ClientValidation(this.name, this.password)
        //     this.errors = validations.checkVelidations();
        //     if('name' in this.errors || 'password' in this.errors){
        //         return false;
        //     }
        //     this.showLoading(true);
        //     try{
        //         await this.client_register({
        //             user_id: this.userId,
        //             name: this.name, 
        //             password: this.password, 
        //             address: this.address,
        //             postcode: this.postcode
        //             });
        //     }catch(e){
        //         this.showLoading(false);
        //         this.serverError = e;
        //         return false;
        //     }
        //     this.showLoading(false);
        //     //this.$router.push('/organizations');
        //      this.$router.push('/login');
        // }
        async onClientregister(){
            this.serverError = '';
            let validations = new ClientValidation(this.first_name,this.last_name, this.password)
            this.errors = validations.checkVelidations();
            if('first_name' in this.errors || 'last_name' in this.errors || 'password' in this.errors){
                return false;
            }
            this.showLoading(true);
            
             this.userId = this.$route.params.userid;
           //console.log("form-submit");
           this.showLoading(true);
            this.errors = [];            
            let bodyFormData = new FormData();
            bodyFormData.append('id_user', this.userId);
            bodyFormData.append('first_name', this.first_name);
            bodyFormData.append('last_name', this.last_name);
            bodyFormData.append('password', this.password); 
            bodyFormData.append('conf_password', this.conf_password);
            bodyFormData.append('phone', this.phone); 
            bodyFormData.append('postcode', this.postcode);
            bodyFormData.append('country', this.country);

            //bodyFormData.append('hotspots', JSON.stringify(this.hotspots));       
            let response = '';

                try{
                        response = await axiosInstance.post(`client-register`, bodyFormData);
                        // if(response.status == 200){
                        //     return response.data.data;                
                        // }
                        if(response.status == 200){ 
                                //this.$router.push({path : '/login'})  
                                this.serverError = 'Succesfully Registered, Please Check Your Email';
                                    this.showLoading(false); 
                                //setTimeout(function(){ location.reload(); }, 3000); 
                                this.$router.push({path : '/congrat/'+this.userId})       
                                return 'The file uploaded successfully successfully!!';
                                 
                            } else{
                                this.$router.push({path : '/client-register/'+this.userId})  
                                this.serverError = 'Something Wrong';
                                    this.showLoading(false); 
                                setTimeout(function(){ location.reload(); }, 3000);       
                                return 'The file uploaded successfully successfully!!';

                            }     
                    }catch(error){
                        throw error.response.status;
                    }
                

                
        }
        
        
    }
        
    
}
</script>