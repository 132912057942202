import Validations from "./Validations";

export default class ClientValidation{
    constructor(first_name, last_name, password){
        this.first_name = first_name;
        this.last_name = last_name;
        this.password = password;
    }

    checkVelidations(){
        let errors = [];

        // Email validation
        if(!Validations.minLength(this.first_name,3)){
            errors['first_name'] = 'provide-firstname';
        }
        if(!Validations.minLength(this.last_name,3)){
            errors['last_name'] = 'provide-lastname';
        }


        // Password validation
        if(!Validations.minLength(this.password, 6)){
            errors['password'] = 'provide-password';
        }

        return errors;
    }

    static getErrorMessageFromCode(errorCode, errors){
        let errorsObj = {};
        if(errorCode == 422){
            if(errors.email){
                errorsObj.email = errors.email[0];
            }
        }
        return errorsObj;
    }

    static getErrorMessageFromLogin(errorCode){
        let errorm = '';
        if(errorCode){
            errorm = 'server-login-error';
        }
        return errorm;
    }

    static getErrorMessageFromProfile(errorCode){
        let errorm = '';
        if(errorCode){
            errorm = 'Something went wrong, please try again later';
        }
        return errorm;
    }
}